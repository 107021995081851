/* eslint-disable */
import jQuery from "jquery";
export function photoGuideToggle() {
  $(".photo-guide .view-photo-guide").toggleClass("opened");
  $(".photo-guide-wrapp").slideToggle();
}
export function gridHeight() {
  // var maxHeight = 0;
  // $("#root .vet-dashboard .search-result-wrapper .shift-box-grid div[class*='col-']").each(
  //   function () {
  //     if ($(this).height() > maxHeight) {
  //       maxHeight = $(this).height();
  //     }
  //   }
  // );
  // $("#root .vet-dashboard .search-result-wrapper .shift-box-grid div[class*='col-']").height(
  //   maxHeight
  // );
}

export function prevTrainingSlide(prevSlide) {
  prevSlide();
  setTimeout(function () {
    if (
      $(".slick-slider .slick-list .slick-track .slick-slide:first-child").hasClass("slick-current")
    ) {
      $(".slick-arrow.slick-prev").fadeOut();
    }
    if ($(".slick-slider .slick-list .slick-track .slick-slide:last-child").not(".slick-current")) {
      $("#trainingSlider .finish-training").fadeOut();
      $(".slick-arrow.slick-next").fadeIn();
    }
  }, 100);
}
export function nextTrainingSlide(nextSlide) {
  if (!nextSlide) {
    return;
  }
  nextSlide();
  setTimeout(function () {
    if (
      $(".slick-slider .slick-list .slick-track .slick-slide:last-child").hasClass("slick-current")
    ) {
      $("#trainingSlider .finish-training").fadeIn();
      $(".slick-arrow.slick-next").fadeOut();
      if ($(window).width() > 768) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
      }
    }

    if (
      $(".slick-slider .slick-list .slick-track .slick-slide:nth-child(2)").hasClass(
        "slick-current"
      )
    ) {
      $(".slick-arrow.slick-prev").fadeIn();
    }
  }, 100);
}

export function customTooltipHtml(
  title,
  shiftTime,
  timezone,
  shiftDate,
  dayDiff,
  isShiftWithZeroRequest,
  isPostEx,
  totalShiftBoostAmount
) {
  if (isShiftWithZeroRequest && !isPostEx && totalShiftBoostAmount === null) {
    $("body").append(
      `<div class='event-tooltip'>Shift has 0 requests and has ${dayDiff} days until ${shiftDate}</div>`
    );
  } else {
    $("body").append(
      `<div class='event-tooltip'><span>${title}</span><span>${shiftTime}</span><span>${timezone}</span></div>`
    );
  }
}

export function customNoteTooltipHtml(title) {
  $("body").append(
    `<div class='event-tooltip' style="max-width:400px"><label class='d-block'>Note</label><span style="word-break:break-word" >${title}</span></div>`
  );
}

export function customTooltipMoreInfoHtml(message, style, containerStyle) {
  style = style ? style : "width: 120px";
  $("body").append(
    `<div class='event-tooltip' style="${containerStyle}"><span style="${style}">${message}</span></div>`
  );
}

export function customFlexInfoHtml(shiftTime, flexShiftTime, className, amount) {
  $("body").append(`<div class='event-tooltip tooltip-width'>
    <div>
    <span class='d-inline-block'>Shift Time:</span>
    <span class='d-inline-block'>${shiftTime}</span>
    </div>
    <div>
    <span class='d-inline-block'>Requested Time:</span>
    <span class='d-inline-block'>${flexShiftTime}</span>
    </div>
    <div>
    <span class='d-inline-block'>Shift Amount:</span>
    <span class=${className}>${amount}</span>
    </div>
  </div>
  `);
}

export function customFlexSurgeryInfoHtml(surgeryTypes, className, amount) {
  $("body").append(`<div class='event-tooltip tooltip-width'>
    <div>
    <span class='d-inline-block'>Surgery Types:</span>
    <span class='d-inline-block'>${surgeryTypes}</span>
    </div>
    <div>
    <span class='d-inline-block'>Requested Surgery Types:</span>
    <span class='d-inline-block'>None</span>
    </div>
    <div>
    <span class='d-inline-block'>Shift Amount:</span>
    <span class=${className}>${amount}</span>
    </div>
  </div>
  `);
}

export function customTooltipMove() {
  var tooltips = $(".event-tooltip");
  window.onmousemove = function (e) {
    var x = e.clientX + 10 + "px";
    var y = e.clientY + 10 + "px";
    for (var i = 0; i < tooltips.length; i++) {
      tooltips.css({
        top: y,
        left: x,
      });
    }
  };
}
export function customTooltipRemove() {
  $(".event-tooltip").remove();
}

(function ($) {
  "use strict"; // Start of use strict
  // var windowH = $('#root .calendar-container').innerHeight() - 150;
  // $('#root .calendar-container .columns').css('height', windowH);
  // window.onresize = function() {
  //   if (window.innerWidth >= 820) {
  //     var windowH = $('#root .calendar-container').innerHeight() - 150;
  //     $('#root .calendar-container .columns').css('height', windowH);
  //    }
  // }
  // Smooth scrolling using jQuery easing
  $(".js-scroll-trigger").bind("touchend click", function () {
    $("#navbarSupportedContent > ul > li").removeClass("active");
    $(this).parent().addClass("active");
    $("body").removeClass("opacity-body");
    if (
      location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html, body").animate(
          {
            scrollTop: target.offset().top - 60,
          },
          1000,
          "easeInOutExpo"
        );

        return false;
      }
    }
  });

  // $( "#trainingSlider .carousel-control-prev" ).click( function() {
  //   // setTimeout(function(){
  //   if( $( "#trainingSlider .carousel-inner .carousel-item:nth-child(2)" ).hasClass( "active" ) ) {
  //     $( "#trainingSlider .carousel-control-prev" ).fadeOut();
  //   }
  //   if( $( "#trainingSlider .carousel-inner .carousel-item:last-child" ).hasClass( "active" ) ) {
  //     $( "#trainingSlider .carousel-control-next" ).fadeIn();
  //   }
  //   // }, 700);
  // } );
  // $( "#trainingSlider .carousel-control-next" ).click( function() {
  //   if( $( "#trainingSlider .carousel-inner .carousel-item:first-child" ).hasClass( "active" ) ) {
  //     $( "#trainingSlider .carousel-control-prev" ).fadeIn();
  //   }

  //   if( $( "#trainingSlider .carousel-inner .carousel-item.second-last-slide" ).hasClass( "active" ) ) {
  //     $( "#trainingSlider .carousel-control-next" ).fadeOut();
  //   }
  // });
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 120) {
      $("header").addClass("fixed-header");
    } else {
      $("header").removeClass("fixed-header");
    }
  });
  $(".menu-btn").click(function () {
    $("body").toggleClass("opacity-body");
  });
  // $( "#root .vet-dashboard .filter-wrapper .actions .btn" ).click( function() {

  // } );
})(jQuery); // End of use strict
