import jQuery from "jquery";
(function ($) {
  "use strict"; // Start of use strict

  // Smooth scrolling using jQuery easing

  // var sidebarH = $( ".right-sidebar" ).outerHeight() - 45;
  // $( ".right-sidebar .toggle-list" ).css( "height", sidebarH );
  // $( window ).resize( function() {
  //   var sidebarH = $( ".right-sidebar" ).outerHeight() - 45;
  //   $( ".right-sidebar .toggle-list" ).css( "height", sidebarH );
  // } );
  // $( ".toggle-list" ).on( "click", ".head", function() {
  //   $( this ).toggleClass( "active" ).next().slideToggle( 200 );
  // } );
  // $( ".toggle-info" ).on( "click", "span", function() {
  //   $( this ).toggleClass( "active" ).next().slideToggle( 200 );
  // } );

  // var calendarMonthYear = $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-toolbar-label" ).text();
  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > button:nth-child(2)" ).after( "<label>" + calendarMonthYear + "</label>" );

  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > button:nth-child(2):contains('Back')" ).addClass( "backBtn" );
  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > button:last-child:contains('Next')" ).addClass( "nextBtn" );
  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > button:first-child:contains('Today')" ).addClass( "todayBtn" );

  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > .todayBtn" ).click( function() {
  //   setTimeout( function() {
  //     $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > label" ).html( $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-toolbar-label" ).text() );
  //   }, 200 );
  // } );

  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > .backBtn" ).click( function() {
  //   setTimeout( function() {
  //     $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > label" ).html( $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-toolbar-label" ).text() );
  //   }, 200 );
  // } );

  // $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > .nextBtn" ).click( function() {
  //   setTimeout( function() {
  //     $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child > label" ).html( $( ".calendar-wrap .rbc-calendar .rbc-toolbar .rbc-toolbar-label" ).text() );
  //   }, 200 );
  // } );
})(jQuery); // End of use strict
